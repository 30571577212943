<template>
  <v-icon :color="color">{{ icon }}</v-icon>
</template>

<script>
export default {
  name: "CheckParIcon",
  props: {
    checkPar: {
      type: Object,
      default: () => ({
        parId: 0,
        pointOrderId: 0,
        name: "",
      }),
    },
  },
  computed: {
    isParAlreadyAddedToDiagnosis() {
      return this.$store.getters["diagnosis/addedPares"].some(
        (diagnosePar) => diagnosePar.par.id === this.checkPar.parId
      );
    },
    isParAlreadyChecked() {
      return this.$store.getters["pares/alreadyCheckedParesOnlyIds"].includes(
        this.checkPar.pointOrderId
      );
    },
    color() {
      if (this.isParAlreadyChecked || this.isParAlreadyAddedToDiagnosis) {
        return "primary";
      }
      return "gray";
    },
    icon() {
      if (this.isParAlreadyAddedToDiagnosis) {
        return "radio_button_checked";
      }
      return "radio_button_unchecked";
    },
  },
};
</script>

<style scoped></style>
