<template>
  <div v-if="existsCheckPares">
    <div>
      <span class="font-weight-bold">Checar pares</span>
      <legends />
    </div>
    <v-list>
      <v-list-item
        v-for="(check, indexCheck) in value"
        :key="indexCheck"
        class="border-bottom-grey"
        @click="checkPar(check.pointOrderId)"
      >
        <v-list-item-content>
          <v-list-item-title>
            <check-par-icon :check-par="check" class="mr-1" />
            {{ check.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <span v-show="check.alreadyAddedToDiagnose">
              Par já adicionado ao diagnóstico
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import CheckParIcon from "@/domains/therapy/steps/execute/domains/check-pares/presentation/components/CheckParIcon";
export default {
  components: {
    CheckParIcon,
    legends: () => import("./Legends"),
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    canOpen: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    existsCheckPares() {
      return this.value.length > 0;
    },
  },
  methods: {
    async checkPar(pointOrderId) {
      this.$emit("click");
      if (!this.canOpen) return;
      try {
        await this.$store.dispatch("pares/selectCheckPar", pointOrderId);
        this.$store.commit("dialogs/setParesCheckPar", true);
      } catch (error) {
        this.$errorHandler(error);
      }
    },
  },
};
</script>

<style></style>
